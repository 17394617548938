import MenuItem from '../MenuItem/MenuItem';

import { SidebarMenuContainer, WorkbookItem } from './SidebarMenu.styled';
import MenuItemPlaceholder from './SidebarMenuPlaceholder';

import { useAuth } from 'context/authContext';
import { action } from 'services/analytics/constants';
import useGoogleAnalytics from 'services/analytics/useGoogleAnalytics';
import useIsFortress from 'services/hooks/useIsFortress';
import useQueryParams from 'services/hooks/useQueryParams';
import { colors } from 'theme';

interface Props {
  options: Record<number, ProjectHierarchyObject[]>;
  isLoading?: boolean;
}

const SidebarMenu = ({ options, isLoading }: Props) => {
  const [{ clientId, fundId, workbookId }, setQueryParams] = useQueryParams();
  const { siteId } = useAuth();

  const { sendInteractionEvent } = useGoogleAnalytics();

  const { isFortress } = useIsFortress();

  const handleWorkbookChange = (id: string) => {
    setQueryParams({ workbookId: id });

    sendInteractionEvent({
      action: action.interaction.reportChanged,
      params: {
        clientId,
        site_id: siteId,
        fund_id: fundId,
        report_id: id,
      },
    });
  };

  const getLevelOfNesting = (depth = 0, parentId: string) => {
    const currentLevel = options[depth];
    const filteredLevel = currentLevel?.filter(
      item => item.parent_id === parentId,
    );

    if (!filteredLevel) return null;

    return filteredLevel.map(({ workbooks, id, name }) => {
      if (workbooks && workbooks.length > 0) {
        return (
          <MenuItem key={id} levelOfNest={depth} name={name}>
            {workbooks.map(item => (
              <WorkbookItem
                $isActive={workbookId === item.id}
                $levelOfNest={depth}
                $primaryColor={
                  isFortress ? colors.primaryFortress : colors.primary
                }
                key={item.id}
                role="link"
                onClick={() => handleWorkbookChange(item.id)}>
                {item.name}
              </WorkbookItem>
            ))}
          </MenuItem>
        );
      }

      return (
        <MenuItem key={id} levelOfNest={depth} name={name}>
          {getLevelOfNesting(depth + 1, id)}
        </MenuItem>
      );
    });
  };

  return (
    <SidebarMenuContainer>
      {isLoading ? <MenuItemPlaceholder /> : getLevelOfNesting(0, fundId)}
    </SidebarMenuContainer>
  );
};

export default SidebarMenu;
