import useQueryParams from './useQueryParams';

const FORTRESS_ID = '0997eda8-1ed6-4181-afa7-16f3b99cd5d7';

const useIsFortress = () => {
  const [{ clientId }] = useQueryParams();

  return { isFortress: clientId === FORTRESS_ID, isLoading: !clientId };
};

export default useIsFortress;
