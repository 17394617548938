import { colors as baseColors } from '@hummingbird/shared';

const colors = {
  ...baseColors,
  gray4: '#F4F3F5',
  primary: '#8800FF',
  primaryFortress: '#269AEF',
} as const;

export default colors;
