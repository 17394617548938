import { fontSizes, fontWeights, hexWithOpacity } from '@hummingbird/shared';
import styled from 'styled-components/macro';

import { boxShadows, colors } from 'theme';

export const SidebarMenuContainer = styled.menu`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  overflow: auto;
  background-color: ${colors.white};
  border-radius: 12px;
  box-shadow: ${boxShadows.elevation2};
`;

interface WorkbookItemProps {
  $isActive: boolean;
  $levelOfNest: number;
  $primaryColor: string;
}

export const WorkbookItem = styled.button<WorkbookItemProps>`
  display: flex;
  align-items: center;
  margin: 0 -20px;
  padding: 8px 0 8px ${({ $levelOfNest }) => ($levelOfNest + 2) * 16}px;
  color: ${colors.night};
  font-weight: ${({ $isActive }) =>
    $isActive ? fontWeights.medium : fontWeights.regular};
  font-size: ${fontSizes.xs};
  text-align: left;
  background-color: ${({ $isActive, $primaryColor }) =>
    $isActive && hexWithOpacity($primaryColor, 0.1)};
  cursor: pointer;

  :hover {
    background: ${({ $primaryColor }) => hexWithOpacity($primaryColor, 0.1)};
  }
`;
