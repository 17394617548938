import { Dropdown, Flex } from '@hummingbird/shared';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ClientButton from './ClientButton/ClientButton';
import { getFirstWorkbook } from './helpers';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import {
  FundDropdown,
  LoaderContainer,
  SettingsMenuContainer,
  SidebarContainer,
  SpinnerIcon,
  UserData,
  WelcomeNote,
} from './Sidebar.styled';
import SidebarMenu from './SidebarMenu/SidebarMenu';
import useSitesDropdown from './useSitesDropdown';

import { FortressLogo, LogoLetters } from 'assets/icons';
import { useAuth } from 'context/authContext';
import { action } from 'services/analytics/constants';
import useGoogleAnalytics from 'services/analytics/useGoogleAnalytics';
import { useProjectsHierarchy } from 'services/api';
import useIsFortress from 'services/hooks/useIsFortress';
import useQueryParams from 'services/hooks/useQueryParams';

const Sidebar = () => {
  const { t } = useTranslation();

  const { sendInteractionEvent } = useGoogleAnalytics();

  const [{ clientId, fundId, workbookId }, setQueryParams] = useQueryParams();
  const { isFortress, isLoading } = useIsFortress();

  const { accountInfo, siteId } = useAuth();

  const { sitesDropdownOptions, isSitesDropdownDisplayed, onSiteChange } =
    useSitesDropdown();

  const { data: projectHierarchy, isLoading: isLoadingHierarchy } =
    useProjectsHierarchy({
      enabled: !!accountInfo?.tenantId,
      onSuccess: data => {
        const { clients, funds, ...rest } = data;

        const currentClientId = clientId || data?.clients[0]?.id;
        const firstFund = data?.funds.find(
          item => item.parent_id === currentClientId,
        );

        const currentFundId = fundId || firstFund?.id;

        setQueryParams({
          clientId: currentClientId,
          fundId: currentFundId,
          workbookId:
            workbookId || getFirstWorkbook(rest, currentFundId || '')?.id,
        });
      },
    });

  const fundOptions = projectHierarchy?.funds
    .filter(item => item.parent_id === clientId)
    .map(item => ({
      value: item.id,
      label: item.name,
    }));

  const { clients, funds, ...rest } = projectHierarchy
    ? projectHierarchy
    : { clients: [], funds: [] };

  const handleFundChange = (value: string) => {
    setQueryParams({
      fundId: value,
      workbookId: getFirstWorkbook(rest, value)?.id,
      viewId: null,
    });

    sendInteractionEvent({
      action: action.interaction.fundChanged,
      params: {
        clientId,
        site_id: siteId,
      },
    });
  };

  const logo = useMemo(() => {
    if (isLoading) {
      return null;
    }

    return isFortress ? (
      <FortressLogo height={33} width={131} />
    ) : (
      <LogoLetters />
    );
  }, [isFortress, isLoading]);

  return (
    <SidebarContainer>
      <UserData>
        {logo}

        <WelcomeNote>
          {t('general.welcome')}, <b>{accountInfo?.username}</b>
        </WelcomeNote>

        {isSitesDropdownDisplayed && (
          <Flex align="center" gap={8} justify="space-between">
            <ClientButton />

            <LoaderContainer>
              <Dropdown
                inputProps={{
                  label: t('labels.client'),
                  isLabelHidden: true,
                  isErrorInitiallyHidden: true,
                }}
                options={sitesDropdownOptions}
                value={siteId}
                onChange={onSiteChange}
              />
            </LoaderContainer>
          </Flex>
        )}

        <LoaderContainer>
          {isLoadingHierarchy && <SpinnerIcon $hasLabel />}
          <FundDropdown
            inputProps={{
              label: `${t('labels.fund')}:`,
              disabled: isLoadingHierarchy,
            }}
            isDisabled={isLoadingHierarchy}
            options={fundOptions || []}
            value={fundId || ''}
            onChange={value => handleFundChange(value as string)}
          />
        </LoaderContainer>
      </UserData>

      <SidebarMenu isLoading={isLoadingHierarchy} options={rest} />

      <SettingsMenuContainer>
        <SettingsMenu />
      </SettingsMenuContainer>
    </SidebarContainer>
  );
};

export default Sidebar;
