import { Button, SpinnerIcon as BaseSpinnerIcon } from '@hummingbird/shared';
import styled from 'styled-components/macro';

export const SpinnerIcon = styled(BaseSpinnerIcon)`
  z-index: 1;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
`;

export const Container = styled(Button)`
  min-width: unset;
  padding: 8px 16px;
`;
