import { useMsal } from '@azure/msal-react';
import {
  DoublePathArrow,
  DropdownOption,
  IconButton,
  useOnClickOutside,
} from '@hummingbird/shared';
import CryptoJS from 'crypto-js';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { INTERCOM_KEY } from '../../../constants';

import {
  CollapseSidebarButton,
  CollapsibleSidebar,
  Container,
  LargeLogo,
  Overlay,
  SmallLogo,
  SmallScreenHeader,
} from './Header.styled';

import Sidebar from 'components/Sidebar/Sidebar';
import { useViews, useWorkbook } from 'services/api';
import useQueryParams from 'services/hooks/useQueryParams';

interface Props {
  isLargeScreen: boolean;
}

const Header = ({ isLargeScreen }: Props) => {
  const [{ workbookId, viewId }, setQueryParams] = useQueryParams();
  const { update } = useIntercom();
  const { instance } = useMsal();
  const [isSidebarOpened, setSidebarOpened] = useState(false);

  const { data: views } = useViews(workbookId, {
    onSuccess: data => {
      setQueryParams({ viewId: data?.[0]?.id });
    },
  });

  const ref = useRef<HTMLDivElement>(null);

  const closeSidebar = () => {
    setSidebarOpened(false);
  };

  useOnClickOutside(ref, closeSidebar);

  const tabs: DropdownOption[] = useMemo(
    () =>
      views?.map((item: any) => ({
        value: item.id as string,
        label: item.name as string,
      })) || [],
    [views],
  );

  const { data: activeWorkbook } = useWorkbook(workbookId, {
    enabled: !!workbookId,
  });

  // So that sidebar can not be initially opened when screen is resized back to width that had previously opened sidebar
  useEffect(closeSidebar, [isLargeScreen]);

  const smallScreenTitle = useMemo(() => {
    if (!tabs || !activeWorkbook) {
      return;
    }

    const activeTab = tabs.find(tab => tab.value === viewId);

    if (!activeTab) {
      return;
    }

    return `${activeWorkbook.name} / ${activeTab.label}`;
  }, [activeWorkbook, tabs, viewId]);

  useEffect(() => {
    const email = instance.getActiveAccount()?.username;
    const activeTab = tabs.find(tab => tab.value === viewId);

    if (!email || !activeTab || !activeWorkbook) {
      return;
    }

    const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, INTERCOM_KEY);

    hmac.update(email);
    const userHash = hmac.finalize().toString(CryptoJS.enc.Hex);

    update({
      userHash,
      customAttributes: {
        email,
        current_report: `${activeWorkbook.name} / ${activeTab.label}`,
      },
    });
  }, [activeWorkbook, instance, tabs, update, viewId]);

  return (
    <Container>
      {isSidebarOpened && !isLargeScreen && (
        <CollapsibleSidebar ref={ref}>
          <Sidebar />
          <CollapseSidebarButton
            icon={DoublePathArrow}
            label="Collapse Sidebar"
            variant="outlined"
            onClick={closeSidebar}
          />
        </CollapsibleSidebar>
      )}
      <SmallScreenHeader $isSidebarOpened={isSidebarOpened}>
        <SmallLogo />
        <LargeLogo />
        <IconButton
          icon={DoublePathArrow}
          label="Display sidebar"
          variant="simple"
          onClick={() => {
            setSidebarOpened(true);
          }}
        />
        {smallScreenTitle}
      </SmallScreenHeader>

      {isSidebarOpened && !isLargeScreen && <Overlay />}
    </Container>
  );
};

export default Header;
